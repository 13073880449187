import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupService } from 'src/app/services/popup.service';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() title: string;
  @Input() information: string;
  @Input() type: string;
  
  myTypeColor:string;


  constructor(private popUpService : PopupService) { }

  ngOnInit(): void {

   }

  setMyColor(){
    if(this.type=='info'){
      return 'rgba(114, 132, 158,1)'
    }
    if(this.type=='error'){
      return 'rgb(173, 132, 132)'
    }
    if(this.type=='success'){
      return 'rgb(111, 170, 111)'
    }
  }

  closeMe(){
    this.popUpService.closeAlert();
  }

  
}
