import { Component, OnInit, Input, Output } from '@angular/core';
import { url } from 'inspector';
import { OuterSubscriber } from 'rxjs/internal/OuterSubscriber';
import { Url } from 'url';

@Component({
  selector: 'app-imagespacer',
  templateUrl: './imagespacer.component.html',
  styleUrls: ['./imagespacer.component.scss']
})
export class ImagespacerComponent implements OnInit {
  @Input() customImg;
  constructor() { }
  ngOnInit(): void {
  
    console.log(this.customImg);
    this.setClasses();
  }
  setClasses(){
    return this.customImg;
  }

}
