
<div class="outer">
<div class="myContainer">
    <div class="caption">
        <h2>Cookies</h2>
    </div>
    <div class="content">
        <p>Diese Webseite verwendet Cookies um Ihnen ein besseres Verhalten der Webseite zu ermöglichen. </p>
    </div>
    <div class="buttons">
        <div class="btnAccept">
            <button (click)="acceptCookies()"  class="btn btn-dark btnaccept" id="loginWithPwd">Akzeptieren</button>

        </div>
        <div class="btnDeny">
            <button (click)="denyCookies()" class="btn btn-dark btndeny" id="loginWithPwd">Ablehnen</button>

        </div>
    </div>
</div>
</div>