<div class="popUpContainer" [style.visibility]='popupContainerVisbility'>
    <div class="cookiecontainer"[style.visibility]='cookieContainerVisbility' >
        <app-cookieaccept></app-cookieaccept>
    </div>
    </div>
    
<div class="alertContainer" [style.visibility]='alertContainerVisbility' >
<div class="alert" [style.visibility]='alertVisbility' [style.opacity]='opacity'>
    <app-alert title={{alertTitle}} information={{alertInformation}} type={{alertType}}></app-alert>
</div>
</div>