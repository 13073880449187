<div class="container-fluid">

<div class="d-flex cardHeader align-left">
<img src={{cardimage}}>
</div>

<div class="cardContent">
    <h4>{{cardtitle}}</h4>
    <div class="col-text">
    {{cardtext}}
    </div>
</div>

</div>