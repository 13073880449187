import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-singlecard',
  templateUrl: './singlecard.component.html',
  styleUrls: ['./singlecard.component.scss']
})
export class SinglecardComponent implements OnInit {
  @Input() cardtitle : string;
  @Input() cardtext: string;
  @Input() cardimage: string;
  constructor() { }

  ngOnInit(): void {
    
  }

}
