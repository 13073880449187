
<h2>Ihre Vorteile durch ModSeven Engineering</h2>



<div class="row ">
    <!-- TODO: Texte aus Datenbank holen -->
    <div class="d-flex col-text ">
        <app-singlecard cardimage="assets/images/bulb.png" cardtitle="Überblick" cardtext="Sie behalten über Ihr Projekt stets den Überblick. Sei es durch die effektive Kommunkation mit uns oder durch unsere leicht verständlichen Dokumentation, die wir für Sie anfertigen. Der Projektfortschitt kann von Ihnen auf unserer Platform eingesehen werden."></app-singlecard>
    </div>
    <div class="d-flex col-text">
        <app-singlecard cardimage="assets/images/control.png" cardtitle="Kontrolle" cardtext="Sie Behalten stets die Kontrolle über Ihr Projekt und dessen Kosten. Wir reagieren flexibel auf Ihre Wünsche auch dann, wenn das Projekt bereits weiter Fortgeschritten ist."></app-singlecard>
    </div>
    <div class="d-flex col-text">
        <app-singlecard cardimage="assets/images/inter.png" cardtitle="Interdisziplinär" cardtext="Aufgrund unserer Vielfalt bieten wir für Sie den Vorteil von perfekt aufeinander abgestimmter Schnitstellen. Auf unser Interdisziplinäres Know-How haben Sie jederzeit Zugriff für Ihre komplexen Systeme."></app-singlecard>
    </div>
</div>


