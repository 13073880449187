<main class="py-1"><!-- DAS HIER IST ITNERESSANT, SOLLTE MAN IMMER VERWNDEN! -->
    
        
    <div class="container">
                
        <div class="myContainer">

            <div class="loginform">
                
                
                <h2 class="text-center">Login</h2>
                        
                <FORM [formGroup]="reactiveForm" (ngSubmit)="onSubmit()" novalidate>


                    <div class="row py-2">
                        <div class="col-6"><label for="uname"><b>Username</b></label></div>
                        <div class="col-6"><input type="text" placeholder="" id="username" name="username" formControlName="username" required></div>
                    </div>
                    <div class="row py-2">
                        <div class="col-6"> <label for="psw"><b>Password</b></label></div>
                        <div class="col-6"> <input type="password" placeholder="" id="password" name="password" formControlName="password" required>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-6"></div>
                        <div class="col-6"> <button class="btn btn-dark" type="submit" id="loginWithPwd">Login</button>
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-6"></div>
                        <div class="col-6"><a [routerLink]="'/register'">Noch keinen Account?</a></div>
                    </div>

                
                </FORM>

            </div>


        </div>
    </div>
    
</main>