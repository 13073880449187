<div class="footer">

    <div class="d-flex footertext">
        <div class="col-6 justify-content-start text-left">Copyright 2020 by ModSeven</div>
        <div class="col-6 justify-content-end text-right">
            <a [routerLink]="'/impressum'">Impressum </a>
            <a [routerLink]="'/datenschutz'">Datenschutz</a>
        
    </div>
    
</div>