import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contactmessage } from '../models/contactmessage';
import { Observable } from 'rxjs';
import { UserCredentials } from '../models/user-credentials';
import { myCookieService } from './cookies.service';
import { UserDetails } from '../models/UserDetails';


@Injectable({ providedIn: 'root' })
export class RestclientService {


  headerJson = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': `${this.cookies.getCookie("jwt")}`
      }
    ), withCredentials: true
  }

  apiUrl:string = 'https://api.modseven.net';

  //apiUrl: string = 'http://localhost:2001';

  contactUrl: string = this.apiUrl + '/contact/add/';
  contactMessageUrl: string = this.apiUrl + '/contact/messages';
  deleteMessageUrl: string = this.apiUrl + '/contact/delete';
  registeUrl: string = this.apiUrl + '/user/register';
  loginUrl: string = this.apiUrl + '/authenticate';
  logoutUrl: string = this.apiUrl + '/user/logout';

  constructor(private http: HttpClient, private cookies: myCookieService) { }

  sendContactMessageToServer(contactmessage: Contactmessage): Observable<any> {
    this.refreshHeader()
    return this.http.post(this.contactUrl, contactmessage, this.headerJson);
  }

  getMessages(): Observable<any> {
    this.refreshHeader()
    return this.http.get(this.contactMessageUrl, this.headerJson);
  }
  deleteMessage(mid: number): Observable<any> {
    this.refreshHeader()
    var jsonDeleteID = { 'id': mid };
    return this.http.post(this.deleteMessageUrl, jsonDeleteID, this.headerJson);
  }
  userLogin(userCredentials: UserCredentials): Observable<any> {
    this.refreshHeader()
    return this.http.post(this.loginUrl, userCredentials, this.headerJson);
  }

  userRegister(userDetails: UserDetails): Observable<any> {
    this.refreshHeader()
    return this.http.post(this.registeUrl, userDetails, this.headerJson);
  }

  userLogout(): Observable<any> {
    this.refreshHeader()
    return this.http.get(this.logoutUrl, this.headerJson);
  }

  refreshHeader() {

    this.headerJson = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': `${this.cookies.getCookie("jwt")}`
        }
      ), withCredentials: true
    }
  }

}
