import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserCredentials } from 'src/app/models/user-credentials';
import { RestclientService } from 'src/app/services/restclient.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Router } from '@angular/router';
import { AlertComponent } from '../../utils/alert/alert.component';
import { myCookieService } from 'src/app/services/cookies.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Output() loginUser: EventEmitter<UserCredentials> = new EventEmitter();
  
  @Output() alertTitle : string;
  @Output() alertInformation : string;

  reactiveForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  })

  constructor(private restclientserivce: RestclientService, private router: Router, private cookies :myCookieService,private popUpService : PopupService) { this.cookies.deleteCookie("jwt")}

  ngOnInit(): void {

    this.cookies.deleteCookie("jwt")
  }
 
  onSubmit() {
    var usercredentials = {
      username: this.reactiveForm.get('username').value,
      password: this.reactiveForm.get('password').value
    }

    this.cookies.deleteCookie("jwt")
    return this.restclientserivce.userLogin(usercredentials).subscribe(response => {
      if (response.status == 'OK') {
        
        this.cookies.deleteCookie("jwt")
        this.cookies.setCookie('jwt',`Bearer ${response.token}`);
        console.log("COOKE BEKOMMEN: " + response.token)
        this.router.navigate(['/messages']);
        this.popUpService.showAlert({message: "Login war erfolgreich", type: "success", duration:5000})
        
      }
      if (response.status == 'Wrong credentials') {
        this.popUpService.showAlert({message: "Falsche Logindaten eingegeben", type: "error",duration:5000})
        
      } 
      if (response.status == 'Banned') {
        this.popUpService.showAlert({message: "Der Account ist gesperrt", type: "error",duration:5000})
      }

      if (response.status == 'Account disabled') {
        this.popUpService.showAlert({message: "Der Account ist noch nicht überprüft", type: "info",duration:5000})
      }


      //TODO
    });
  }

}

