import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImpressumComponent } from './components/sidestuff/impressum/impressum.component';
import { DatenschutzComponent } from './components/sidestuff/datenschutz/datenschutz.component';
import { MainComponent } from './components/main/main.component';
import { NotfoundComponent } from './components/sidestuff/notfound/notfound.component';
import { LoginComponent } from './components/member/login/login.component';
import { MessagesComponent } from './components/member/messages/messages.component';
import { RegisterComponent } from './components/member/register/register.component';


const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: 'login', component: LoginComponent },
  { path: 'messages', component: MessagesComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'notfound', component: NotfoundComponent},
  { path: '**', redirectTo: '/notfound'}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

