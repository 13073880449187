import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenulogoComponent } from './components/header/menu/menulogo/menulogo.component';
import { NavbarComponent } from './components/header/menu/navbar/navbar.component';

import { HeadercontainerComponent } from './components/header/headercontainer/headercontainer.component';
import { GeneralinfoComponent } from './components/main/overview/generalinfo/generalinfo.component';
import { CardinfoComponent } from './components/main/overview/cardinfo/cardinfo.component';
import { OverviewcontainerComponent } from './components/main/overview/overviewcontainer/overviewcontainer.component';
import { ImagespacerComponent } from './components/main/overview/imagespacer/imagespacer.component';
import { SinglecardComponent } from './components/main/overview/singlecard/singlecard.component';
import { LeistcardComponent } from './components/main/leistungen/leistcard/leistcard.component';
import { LeistcontainerComponent } from './components/main/leistungen/leistcontainer/leistcontainer.component';
import { FooterbarComponent } from './components/footer/footerbar/footerbar.component';
import { ContactformComponent } from './components/main/contactform/contactform.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImpressumComponent } from './components/sidestuff/impressum/impressum.component';
import { DatenschutzComponent } from './components/sidestuff/datenschutz/datenschutz.component';
import { MainComponent } from './components/main/main.component';
import { DropdownComponent } from './components/header/menu/dropdown/dropdown.component';
import { NotfoundComponent } from './components/sidestuff/notfound/notfound.component';
import { LoginComponent } from './components/member/login/login.component';
import { MessagesComponent } from './components/member/messages/messages.component';
import { MsgtemplateComponent } from './components/member/messages/msgtemplate/msgtemplate.component';
import { AlertComponent } from './components/utils/alert/alert.component';
import { CookieacceptComponent } from './components/utils/cookieaccept/cookieaccept.component';
import { PopupcontainerComponent } from './components/utils/popupcontainer/popupcontainer.component';
import { RegisterComponent } from './components/member/register/register.component';


@NgModule({
  declarations: [
    AppComponent,
    MenulogoComponent,
    NavbarComponent,
    HeadercontainerComponent,
    GeneralinfoComponent,
    CardinfoComponent,
    OverviewcontainerComponent,
    ImagespacerComponent,
    SinglecardComponent,
    LeistcardComponent,
    LeistcontainerComponent,
    FooterbarComponent,
    ContactformComponent,
    ImpressumComponent,
    DatenschutzComponent,
    MainComponent,
    DropdownComponent,
    NotfoundComponent,
    LoginComponent,
    MessagesComponent,
    MsgtemplateComponent,
    AlertComponent,
    CookieacceptComponent,
    PopupcontainerComponent,
    RegisterComponent,
    
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
