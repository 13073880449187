<main class="py-1"><!-- DAS HIER IST ITNERESSANT, SOLLTE MAN IMMER VERWNDEN! -->
    
        
    <div class="container">
                
        <div class="myContainer">

            <div class="loginform">
                
                
                <h2 class="text-center">Registrierung</h2>
                        
                <FORM [formGroup]="reactiveForm" (ngSubmit)="onSubmit()" novalidate>
                    <div class="row py-2">
                        <div class="col-6"><label for="uname"><b>* Username</b></label></div>
                        <div class="col-6"><input type="text" placeholder="" id="username" name="username" formControlName="username" required></div>
                    </div>
                      <div class="row py-2">
                        <div class="col-6"><label for="uname"><b>Vor -und Nachname</b></label></div>
                        <div class="col-6"><input type="text" placeholder="" id="realname" name="realname" formControlName="realname"></div>
                    </div>
                    <div class="row py-2">
                        <div class="col-6"><label for="uname"><b>Addresse</b></label></div>
                        <div class="col-6"><input type="text" placeholder="" id="address" name="address" formControlName="address" ></div>
                    </div>
                    <div class="row py-2">
                        <div class="col-6"><label for="uname"><b>* E-Mail</b></label></div>
                        <div class="col-6"><input type="text" placeholder="" id="email" name="email" formControlName="email" required></div>
                    </div>
                    <div class="row py-2">
                        <div class="col-6"><label for="uname"><b>Telefonnummer</b></label></div>
                        <div class="col-6"><input type="text" placeholder="" id="phone" name="phone" formControlName="phone"></div>
                    </div>
                    <div class="row py-2">
                        <div class="col-6"> <label for="psw"><b>* Password</b></label></div>
                        <div class="col-6"> <input type="password" placeholder="" id="password" name="password" formControlName="password" required>
                        </div>
                    </div>
                    <div class="row py-3">
                        <div class="col-6"> Felder mit * werden benötigt</div>
                        <div class="col-6">  </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-6"></div>
                        <div class="col-6"> <button class="btn btn-dark" type="submit" id="register">Registrieren</button>
                        </div>
                    </div>

                
                </FORM>

            </div>


        </div>
    </div>
    
</main>