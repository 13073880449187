<div class="myContainer" [style.height.rem]="height">
    <div  class="headerLogo"[style.margin.rem]="margin">
        <app-menulogo></app-menulogo>
    </div>
    <div class="headerNavbar">
        <app-navbar></app-navbar>
    </div>
    <div class="dropDownMenu">
        <app-dropdown></app-dropdown>
    </div>
</div>


<!-- TODO: Menubutton ab einer bestimmten groesse einblenden -->