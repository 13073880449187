import { Component, OnInit, Input } from '@angular/core';
import { RestclientService } from 'src/app/services/restclient.service';
import { PopupService } from 'src/app/services/popup.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-msgtemplate',
  templateUrl: './msgtemplate.component.html',
  styleUrls: ['./msgtemplate.component.scss']
})
export class MsgtemplateComponent implements OnInit {
  @Input() datetime: string;
  @Input() name: string;
  @Input() id: number;
  @Input() email: string;
  @Input() subject: string;
  @Input() phone: string;
  @Input() message: string;
  constructor(private router: Router,private restclientService : RestclientService, private popUpService : PopupService) { }

  ngOnInit(): void {
    
  }

  deleteMessage(){
    
    return this.restclientService.deleteMessage(this.id).subscribe(response => {
      console.log(response);
      if (response.status == 'OK') {            
        //TODO Router ist nur Workaround. Refresh ueber event nach messages.component
      
        this.popUpService.showAlert({message: "Nachricht gelöscht", type: "success", duration:5000})
    
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/messages']);
    
      } else  {
        this.popUpService.showAlert({message: "Fehler beim löschen", type: "error", duration:5000})
    
      } 

    
    });

  }
}
