<div id="targetKontakt" style="height: 5rem;"> </div>
<div class="">
    <div class="cntHead">
        <h2>Kontaktieren Sie uns</h2>
        <div class="col-text text-center">
            <p> Kontaktieren Sie uns mit Ihrem Anliegen und vereinbaren Sie gerne mit uns einen Termin</p>
            <h5>Lassen Sie uns gemeinsam Lösungen finden!</h5>
        </div>

    </div>
    <FORM [formGroup]="reactiveForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="frmOuter form-group ">

            <div class="row mWidth py-2 ">
                <div class="col-3">
                    Name/Firma
                </div>
                <div class="col">
                    <input type="text" id="name" name="name" formControlName="name" placeholder="(Erforderlich)">
                </div>
            </div>

            <div class="row mWidth py-2">
                <div class="col-3">
                    E-Mail
                </div>
                <div class="col">
                    <input type="text" id="email" name="email" formControlName="email" placeholder="(Erforderlich)">
                </div>
            </div>

            <div class="row mWidth py-2">
                <div class="col-3">
                    Telefon
                </div>
                <div class="col">
                    <input type="text" id="phone" name="phone" formControlName="phone" placeholder="(Optional)">
                </div>
            </div>

            <div class="row mWidth py-2">
                <div class="col-3">
                    Betreff
                </div>
                <div class="col">
                    <input type="text" id="subject" name="subject" formControlName="subject"
                        placeholder="(Erforderlich)">
                </div>
            </div>

            <div class="row mWidth py-2">
                <div class="col-3">
                    Ihre Nachricht
                </div>
                <div class="col">
                    <textarea type="text" rows="5" id="message" name="message" formControlName="message"
                        placeholder=" (Erforderlich)"></textarea>
                </div>
            </div>
            <div class="row  py-2" [style]="bgColor">
                <div class="col-3"></div>
                <div class="col-5" style="text-align:left">Ich akzeptiere die Datenschutzbestimmungen</div>
                <div class="col-1"><input class="form-check-input" type="checkbox"  id="checkDSB" name="checkDSB" formControlName="checkDSB" ></div>
            </div>
            <div class="row py-2 ">
                <div class="col-3"></div>
                <div class="col">
                    <button type="submit" (onSubmit)="onSubmit" name="sendMessage" id="sendMessage"
                    class="btn btn-primary float-right btn-lg"
                    style="background-color: rgb(42, 37, 46); border-color: rgba(255, 255, 255, 0.5);">Absenden</button>
                </div>
            </div>

        </div>
    </FORM>
</div>