import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardinfo',
  templateUrl: './cardinfo.component.html',
  styleUrls: ['./cardinfo.component.scss']
})
export class CardinfoComponent implements OnInit {

  constructor() {  }
  

  ngOnInit(): void {
    
    }
  
/* Sowas geht auch

  nummer: number;
  mgNum : number;
  timeLeft: number = 60;
  interval;
  posi : number = 1;


    startTimer():void {
      this.interval = setInterval(() => {
        this.posi = this.posi + 0.1;
        if(this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.timeLeft = 60;
        }
        this.nummer = this.timeLeft;
        console.log(this.timeLeft)
        this.mgNum = this.posi;
      },10)
    }
    */
  }

