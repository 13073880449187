import { Component, OnInit } from '@angular/core';
import { PopupService } from 'src/app/services/popup.service';
import { Alertpopup } from 'src/app/models/alertpopup';



@Component({
  selector: 'app-popupcontainer',
  templateUrl: './popupcontainer.component.html',
  styleUrls: ['./popupcontainer.component.scss']
})
export class PopupcontainerComponent implements OnInit {
  
  popupContainerVisbility: String;
  alertContainerVisbility: String;
  cookieContainerVisbility: String;
  alertVisbility: String;
  alertTitle: string;
  alertInformation: string;
  alertType: string;
  alertTransition : string;
  opacity:string;
  constructor(private popUpService: PopupService) {


    this.popUpService.eventShowCookieBox.subscribe(data => {
      this.showCookiePopup(data);
    });


    this.popUpService.eventShowAlert.subscribe(data => {
      this.showAlertPopUp(data);
    });

    this.popUpService.eventCloseAlert.subscribe(data => {
      this.closeAlertPopUp();
    });


  }
  
  ngOnInit(): void {

  }

  showAlertPopUp(data: Alertpopup) {
    if(data.message){
    this.alertInformation = data.message;
    this.alertType = data.type;    
    this.alertContainerVisbility = "visible";
    this.alertVisbility = "visible";
    this.opacity='1';
    setTimeout(() => {this.closeAlertPopUp();},data.duration);
    }
  }

  closeAlertPopUp() {
    this.opacity='0';
    this.alertVisbility = "hidden";
    this.alertContainerVisbility = "hidden";
  }

  showCookiePopup(visible: boolean) {
    if (visible) {
      this.popupContainerVisbility = "visible";
      this.cookieContainerVisbility = "visible";
    } else {
      this.popupContainerVisbility = "collapse";
      this.cookieContainerVisbility = "collapse";
    }
  }

}
