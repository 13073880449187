import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Alertpopup } from '../models/alertpopup';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  @Output() eventShowCookieBox = new EventEmitter<any>();
  @Output() eventShowAlert = new EventEmitter<Alertpopup>();
  @Output() eventCloseAlert = new EventEmitter();
  
  constructor() { }

  public showCookieBox(){
    this.eventShowCookieBox.emit(true);
  }

  public closeCookieBox(){
    this.eventShowCookieBox.emit(false);
  }

  public showAlert(data){
    this.eventShowAlert.emit(data);
  }
  public closeAlert(){
    this.eventCloseAlert.emit();
  }
}
