import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leistcontainer',
  templateUrl: './leistcontainer.component.html',
  styleUrls: ['./leistcontainer.component.scss']
})
export class LeistcontainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
