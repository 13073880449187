
<table>
    
        <td><a href="#targetStart">Start</a></td>
        <td><a href="#targetLeistungen">Leistungen</a></td>
        <td><a href="#targetKontakt">Kontakt</a></td>
        <td><a [routerLink]="'/login'">Login</a></td>
        
</table>



