import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-leistcard',
  templateUrl: './leistcard.component.html',
  styleUrls: ['./leistcard.component.scss']
})
export class LeistcardComponent implements OnInit {
  
  @Input() cardtitle: string;
  @Input() cardtext: string;
  @Input() cardimage: string;
  constructor() { }

  ngOnInit(): void {
  }

}
