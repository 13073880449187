import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overviewcontainer',
  templateUrl: './overviewcontainer.component.html',
  styleUrls: ['./overviewcontainer.component.scss']
})
export class OverviewcontainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
