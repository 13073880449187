import { Component, OnInit, Input, Renderer2, Output, OnDestroy } from '@angular/core';
import { splitClasses } from '@angular/compiler';
import { $ } from 'protractor';

/*
html:
[style.margin]="mgNavbar"
und hier:
public mgNavbar : string;
this.mgNavbar = "auto";
GEHT!!
*/

@Component({
  selector: 'app-headercontainer',
  templateUrl: './headercontainer.component.html',
  styleUrls: ['./headercontainer.component.scss'],

})
export class HeadercontainerComponent implements OnInit, OnDestroy {
  public height: number;
  public margin: number;
  public margintop: boolean;
  public marginbottom: boolean;

  constructor() {
    

  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true); //third parameter

  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {

    console.log(this.margintop)
    if (window.scrollY > 50) {

      this.margin = 0;
      this.height = 5;

    } else {

      this.margin = 1;
      this.height = 12;

    }
  }


}
