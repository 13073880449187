<div class="hamburger" href="#" (click)="openDropDown()">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="hamburgercontent" [style.visibility]="contentVisible">
        <p><a href="#targetStart">Start</a></p>
        <p><a href="#targetLeistungen">Leistungen</a></p>
        <p><a href="#targetKontakt">Kontakt</a></p>
        <p><a [routerLink]="'/login'">Login</a></p>
    </div>


</div>