<app-headercontainer></app-headercontainer>
<!--Main Layout-->
<main class="py-1">

    <div class="container">
        <div class="row">
            <div class='impressum'>
                <h1>Datenschutzerklärung</h1>
                <h2>Einleitung</h2>
                <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                    personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
                    welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
                    Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch
                    insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
                    Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
                    "Onlineangebot“).</p>
                <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
                <p>Stand: 30. März 2020</p>
                <h2 id="m3">Verantwortlicher</h2>
                <p>Modseven<br>Gogolstrasse 8b<br>90475 Nuremberg</p>
                <p><strong>Vertretungsberechtigte Personen</strong>: Philipp Hanzik</p>
                <p><strong>E-Mail-Adresse</strong>: <a
                        href="mailto:info@modseven.net">info@modseven.net</a></p>
                <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                <p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung
                    zusammen und verweist auf die betroffenen Personen.</p>
                <h3>Arten der verarbeiteten Daten</h3>
                <ul>
                    <li>Bestandsdaten (z.B. Namen, Adressen).</li>
                    <li>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</li>
                    <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
                    <li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
                    <li>Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
                    <li>Standortdaten (Daten, die den Standort des Endgeräts eines Endnutzers angeben).</li>
                    <li>Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).</li>
                    <li>Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).</li>
                </ul>
                <h3>Kategorien betroffener Personen</h3>
                <ul>
                    <li>Geschäfts- und Vertragspartner.</li>
                    <li>Interessenten.</li>
                    <li>Kommunikationspartner.</li>
                    <li>Kunden.</li>
                    <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
                </ul>
                <h3>Zwecke der Verarbeitung</h3>
                <ul>
                    <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                    <li>Büro- und Organisationsverfahren.</li>
                    <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
                    <li>Kontaktanfragen und Kommunikation.</li>
                    <li>Vertragliche Leistungen und Service.</li>
                    <li>Verwaltung und Beantwortung von Anfragen.</li>
                </ul>
                <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
                <p>Im Folgenden teilen wir die Rechtsgrundlagen der Datenschutzgrundverordnung (DSGVO), auf deren Basis
                    wir die personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass zusätzlich zu den
                    Regelungen der DSGVO die nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland
                    gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir
                    Ihnen diese in der Datenschutzerklärung mit.</p>
                <ul>
                    <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> - Die betroffene Person hat ihre
                        Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen
                        spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.</li>
                    <li><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b.
                            DSGVO)</strong> - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
                        Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen
                        erforderlich, die auf Anfrage der betroffenen Person erfolgen.</li>
                    <li><strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO)</strong> - Die Verarbeitung
                        ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche
                        unterliegt.</li>
                    <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung
                        ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten
                        erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen
                        Person, die den Schutz personenbezogener Daten erfordern, überwiegen.</li>
                </ul>
                <p><strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu den
                    Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in
                    Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten
                    bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
                    Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur
                    Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und
                    zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling.
                    Des Weiteren regelt es die Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG),
                    insbesondere im Hinblick auf die Begründung, Durchführung oder Beendigung von
                    Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können
                    Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.</p>
                <h2 id="m27">Sicherheitsmaßnahmen</h2>
                <p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
                    Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie
                    der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und
                    Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem
                    Risiko angemessenes Schutzniveau zu gewährleisten.</p>
                <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit
                    von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie
                    betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer
                    Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
                    Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
                    gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
                    Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                    Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>
                <p><strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser Online-Angebot übermittelten Daten zu
                    schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an
                    dem Präfix https:// in der Adresszeile Ihres Browsers.</p>
                <h2 id="m134">Einsatz von Cookies</h2>
                <p>Cookies sind Textdateien, die Daten von besuchten Websites oder Domains enthalten und von einem
                    Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient in erster Linie dazu,
                    die Informationen über einen Benutzer während oder nach seinem Besuch innerhalb eines
                    Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die Spracheinstellungen auf
                    einer Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde,
                    gehören. Zu dem Begriff der Cookies zählen wir ferner andere Technologien, die die gleichen
                    Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand pseudonymer
                    Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs" bezeichnet)</p>
                <p><strong>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</strong></p>
                <ul>
                    <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong>&nbsp;Temporäre
                        Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und seinen
                        Browser geschlossen hat.</li>
                    <li><strong>Permanente Cookies:</strong>&nbsp;Permanente Cookies bleiben auch nach dem Schließen des
                        Browsers gespeichert. So kann beispielsweise der Login-Status gespeichert oder bevorzugte
                        Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die
                        Interessen von Nutzern, die zur Reichweitenmessung oder zu Marketingzwecken verwendet werden, in
                        einem solchen Cookie gespeichert werden.</li>
                    <li><strong>First-Party-Cookies:</strong>&nbsp;First-Party-Cookies werden von uns selbst gesetzt.
                    </li>
                    <li><strong>Third-Party-Cookies (auch: Drittanbieter-Cookies)</strong>: Drittanbieter-Cookies werden
                        hauptsächlich von Werbetreibenden (sog. Dritten) verwendet, um Benutzerinformationen zu
                        verarbeiten.</li>
                    <li><strong>Notwendige (auch: essentielle oder unbedingt erforderliche) Cookies:</strong> Cookies
                        können zum einen für den Betrieb einer Webseite unbedingt erforderlich sein (z.B. um Logins oder
                        andere Nutzereingaben zu speichern oder aus Gründen der Sicherheit).</li>
                    <li><strong>Statistik-, Marketing- und Personalisierungs-Cookies</strong>: Ferner werden Cookies im
                        Regelfall auch im Rahmen der Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines
                        Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf
                        einzelnen Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den
                        Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen Interessen entsprechen. Dieses Verfahren
                        wird auch als "Tracking", d.h., Nachverfolgung der potentiellen Interessen der Nutzer
                        bezeichnet. . Soweit wir Cookies oder "Tracking"-Technologien einsetzen, informieren wir Sie
                        gesondert in unserer Datenschutzerklärung oder im Rahmen der Einholung einer Einwilligung.</li>
                </ul>
                <p><strong>Hinweise zu Rechtsgrundlagen: </strong> Auf welcher Rechtsgrundlage wir Ihre
                    personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Sie um eine
                    Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung von Cookies einwilligen, ist die
                    Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die
                    mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an
                    einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen Verbesserung) verarbeitet
                    oder, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu
                    erfüllen.</p>
                <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong> Abhängig davon, ob die
                    Verarbeitung auf Grundlage einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie
                    jederzeit die Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer
                    Daten durch Cookie-Technologien zu widersprechen (zusammenfassend als "Opt-Out" bezeichnet). Sie
                    können Ihren Widerspruch zunächst mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie
                    die Nutzung von Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres
                    Onlineangebotes eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken
                    des Onlinemarketings kann auch mittels einer Vielzahl von Diensten, vor allem im Fall des Trackings,
                    über die Webseiten <a href="https://optout.aboutads.info"
                        target="_blank">https://optout.aboutads.info</a> und <a
                        href="https://www.youronlinechoices.com/" target="_blank">https://www.youronlinechoices.com/</a>
                    erklärt werden. Daneben können Sie weitere Widerspruchshinweise im Rahmen der Angaben zu den
                    eingesetzten Dienstleistern und Cookies erhalten.</p>
                <p><strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung</strong>: Bevor wir Daten im
                    Rahmen der Nutzung von Cookies verarbeiten oder verarbeiten lassen, bitten wir die Nutzer um eine
                    jederzeit widerrufbare Einwilligung. Bevor die Einwilligung nicht ausgesprochen wurde, werden
                    allenfalls Cookies eingesetzt, die für den Betrieb unseres Onlineangebotes erforderlich sind. Deren
                    Einsatz erfolgt auf der Grundlage unseres Interesses und des Interesses der Nutzer an der erwarteten
                    Funktionsfähigkeit unseres Onlineangebotes.</p>
                <ul class="m-elements">
                    <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                        Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                    </li>
                    <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                        Onlinediensten).</li>
                    <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO), Berechtigte
                        Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                </ul>
                <h2 id="m317">Kommerzielle und geschäftliche Leistungen</h2>
                <p>Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und Interessenten
                    (zusammenfassend bezeichnet als "Vertragspartner") im Rahmen von vertraglichen und vergleichbaren
                    Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen der Kommunikation mit den
                    Vertragspartnern (oder vorvertraglich), z.B., um Anfragen zu beantworten.</p>
                <p>Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen Pflichten, zur Sicherung unserer
                    Rechte und zu Zwecken der mit diesen Angaben einhergehenden Verwaltungsaufgaben sowie der
                    unternehmerischen Organisation. Die Daten der Vertragspartner geben wir im Rahmen des geltenden
                    Rechts nur insoweit an Dritte weiter, als dies zu den vorgenannten Zwecken oder zur Erfüllung
                    gesetzlicher Pflichten erforderlich ist oder mit Einwilligung der Vertragspartner erfolgt (z.B. an
                    beteiligte Telekommunikations-, Transport- und sonstige Hilfsdienste sowie Subunternehmer, Banken,
                    Steuer- und Rechtsberater, Zahlungsdienstleister oder Steuerbehörden). Über weitere
                    Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden die Vertragspartner im Rahmen dieser
                    Datenschutzerklärung informiert.</p>
                <p>Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder
                    im Rahmen der Datenerhebung, z.B. in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben)
                    bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.</p>
                <p>Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d.h.,
                    grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert
                    werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen (z.B.
                    für Steuerzwecke im Regelfall 10 Jahre). Daten, die uns im Rahmen eines Auftrags durch den
                    Vertragspartner offengelegt wurden, löschen wir entsprechend den Vorgaben des Auftrags,
                    grundsätzlich nach Ende des Auftrags.</p>
                <p>Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten im
                    Verhältnis zwischen den Nutzern und den Anbietern die Geschäftsbedingungen und Datenschutzhinweise
                    der jeweiligen Drittanbieter oder Plattformen.</p>
                <p><strong>Mobile Applikation</strong>: Wir verarbeiten die Daten der Nutzer unserer Apps, angemeldeter
                    und etwaiger Testnutzer (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber unsere
                    vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter Interessen, um die
                    Sicherheit unserer App gewährleisten und sie weiterzuentwickeln zu können. Die erforderlichen
                    Angaben sind als solche im Rahmen des Nutzungs-, Auftrags-, Bestell- bzw. vergleichbaren
                    Vertragsschlusses gekennzeichnet und können die zur Leistungserbringung und zur etwaigen Abrechnung
                    benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können, umfassen.
                    Soweit unsere Apps von Plattformen anderer Anbieter (z.B. App-Store von Apple oder GooglePlay)
                    bezogen werden, gelten im Verhältnis zwischen den Nutzern und den Anbietern die Geschäftsbedingungen
                    und Datenschutzhinweise der jeweiligen Plattformen.</p>
                <p><strong>Technische Dienstleistungen</strong>: Wir verarbeiten die Daten unserer Kunden sowie
                    Auftraggeber (nachfolgend einheitlich als "Kunden" bezeichnet), um ihnen die Auswahl, den Erwerb
                    bzw. die Beauftragung der gewählten Leistungen oder Werke sowie verbundener Tätigkeiten als auch
                    deren Bezahlung und Zurverfügungstellung bzw. Ausführung oder Erbringung zu ermöglichen.</p>
                <p>Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
                    Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung und Abrechnung benötigten
                    Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können. Soweit wir Zugang zu
                    Informationen der Endkunden, Mitarbeitern oder anderer Personen erhalten, verarbeiten wir diese im
                    Einklang mit den gesetzlichen und vertraglichen Vorgaben.</p>
                <ul class="m-elements">
                    <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Zahlungsdaten
                        (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Kontaktdaten (z.B. E-Mail,
                        Telefonnummern), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie),
                        Standortdaten (Daten, die den Standort des Endgeräts eines Endnutzers angeben).</li>
                    <li><strong>Betroffene Personen:</strong> Interessenten, Geschäfts- und Vertragspartner.</li>
                    <li><strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service, Kontaktanfragen
                        und Kommunikation, Büro- und Organisationsverfahren, Verwaltung und Beantwortung von Anfragen.
                    </li>
                    <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                        S. 1 lit. b. DSGVO), Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO), Berechtigte
                        Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                </ul>
                <h2 id="m326">Zahlungsdienstleister</h2>
                <p>Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund gesetzlicher Pflichten oder sonst
                    auf Grundlage unserer berechtigten Interessen bieten wir den betroffenen Personen effiziente und
                    sichere Zahlungsmöglichkeiten an und setzen hierzu neben Banken und Kreditinstituten weitere
                    Zahlungsdienstleister ein (zusammenfassend "Zahlungsdienstleister").</p>
                <p>Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie z.B. der Name
                    und die Adresse, Bankdaten, wie z.B. Kontonummern oder Kreditkartennummern, Passwörter, TANs und
                    Prüfsummen sowie die Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben sind
                    erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten werden jedoch nur durch die
                    Zahlungsdienstleister verarbeitet und bei diesen gespeichert. D.h., wir erhalten keine konto- oder
                    kreditkartenbezogenen Informationen, sondern lediglich Informationen mit Bestätigung oder
                    Negativbeauskunftung der Zahlung. Unter Umständen werden die Daten seitens der Zahlungsdienstleister
                    an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die Identitäts- und
                    Bonitätsprüfung. Hierzu verweisen wir auf die AGB und die Datenschutzhinweise der
                    Zahlungsdienstleister.</p>
                <p>Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise der jeweiligen
                    Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten bzw. Transaktionsapplikationen
                    abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer Informationen und Geltendmachung
                    von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.</p>
                <ul class="m-elements">
                    <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Zahlungsdaten
                        (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand,
                        Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                        Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
                    <li><strong>Betroffene Personen:</strong> Kunden, Interessenten.</li>
                    <li><strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service.</li>
                    <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                        S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                </ul>
                <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                <ul class="m-elements">
                    <li><strong>PayPal:</strong> Zahlungsdienstleistungen und -Lösungen (z.B. PayPal, PayPal Plus,
                        Braintree); Dienstanbieter: PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal,
                        L-2449 Luxembourg; Website: <a href="https://www.paypal.com/de"
                            target="_blank">https://www.paypal.com/de</a>; Datenschutzerklärung: <a
                            href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
                            target="_blank">https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>.</li>
                </ul>
                <h2 id="m17">Newsletter und Breitenkommunikation</h2>
                <p>Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen (nachfolgend
                    "Newsletter“) nur mit der Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im
                    Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die
                    Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren
                    Leistungen und uns.</p>
                <p>Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre E-Mail-Adresse
                    angeben. Wir können Sie jedoch bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter,
                    oder weitere Angaben, sofern diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.</p>
                <p><strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter erfolgt grundsätzlich
                    in einem sogenannte Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung eine E-Mail, in
                    der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit
                    sich niemand mit fremden E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden
                    protokolliert, um den Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu
                    können. Hierzu gehört die Speicherung des Anmelde- und des Bestätigungszeitpunkts als auch der
                    IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten
                    protokolliert.</p>
                <p><strong>Löschung und Einschränkung der Verarbeitung: </strong> Wir können die ausgetragenen
                    E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern, bevor
                    wir sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser
                    Daten wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein individueller
                    Löschungsantrag ist jederzeit möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung
                    bestätigt wird. Im Fall von Pflichten zur dauerhaften Beachtung von Widersprüchen behalten wir uns
                    die Speicherung der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste (sogenannte
                    "Blacklist") vor.</p>
                <p>Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zu
                    Zwecken des Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem
                    Versand von E-Mails beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an einem
                    effizienten und sicheren Versandsystem.</p>
                <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Der Versand der Newsletter erfolgt auf Grundlage einer
                    Einwilligung der Empfänger oder, falls eine Einwilligung nicht erforderlich ist, auf Grundlage
                    unserer berechtigten Interessen am Direktmarketing, sofern und soweit diese gesetzlich, z.B. im Fall
                    von Bestandskundenwerbung, erlaubt ist. Soweit wir einen Dienstleister mit dem Versand von E-Mails
                    beauftragen, geschieht dies auf der Grundlage unserer berechtigten Interessen. Das
                    Registrierungsverfahren wird auf der Grundlage unserer berechtigten Interessen aufgezeichnet, um
                    nachzuweisen, dass es in Übereinstimmung mit dem Gesetz durchgeführt wurde.</p>
                <p><strong>Inhalte</strong>: Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
                <p><strong>Erfolgsmessung</strong>: Die Newsletter enthalten einen sogenannte "web-beacon“, d.h., eine
                    pixelgroße Datei, die beim Öffnen des Newsletters von unserem Server, bzw., sofern wir einen
                    Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im Rahmen dieses Abrufs werden
                    zunächst technische Informationen, wie Informationen zum Browser und Ihrem System, als auch Ihre
                    IP-Adresse und der Zeitpunkt des Abrufs, erhoben. </p>
                <p>Diese Informationen werden zur technischen Verbesserung unseres Newsletters anhand der technischen
                    Daten oder der Zielgruppen und ihres Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der
                    IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls die
                    Feststellung, ob die Newsletter geöffnet werden, wann sie geöffnet werden und welche Links geklickt
                    werden. Diese Informationen können aus technischen Gründen zwar den einzelnen Newsletterempfängern
                    zugeordnet werden. Es ist jedoch weder unser Bestreben noch, sofern eingesetzt, das des
                    Versanddienstleisters, einzelne Nutzer zu beobachten. Die Auswertungen dienen uns vielmehr dazu, die
                    Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte an sie anzupassen oder
                    unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu versenden.</p>
                <p>Die Auswertung des Newsletters und die Erfolgsmessung erfolgen, vorbehaltlich einer ausdrücklichen
                    Einwilligung der Nutzer, auf Grundlage unserer berechtigten Interessen zu Zwecken des Einsatzes
                    eines nutzerfreundlichen sowie sicheren Newslettersystems, welches sowohl unseren geschäftlichen
                    Interessen dient, als auch den Erwartungen der Nutzer entspricht.</p>
                <p>Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich, in diesem Fall muss das gesamte
                    Newsletterabonnement gekündigt, bzw. muss ihm widersprochen werden.</p>
                <ul class="m-elements">
                    <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten
                        (z.B. E-Mail, Telefonnummern), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                        IP-Adressen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).
                    </li>
                    <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                    <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder postalisch).
                    </li>
                    <li><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO), Berechtigte
                        Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).</li>
                    <li><strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können den Empfang unseres Newsletters
                        jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen, bzw. dem weiteren Empfang
                        widersprechen. Einen Link zur Kündigung des Newsletters finden Sie entweder am Ende eines jeden
                        Newsletters oder können sonst eine der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig
                        E-Mail, hierzu nutzen.</li>
                </ul>
                <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
                <p>Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer
                    jeweiligen Anbieter (nachfolgend bezeichnet als "Drittanbieter”) bezogen werden. Dabei kann es sich
                    zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen sowie Beiträge handeln (nachfolgend
                    einheitlich bezeichnet als "Inhalte”).</p>
                <p>Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer
                    verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
                    IP-Adresse ist damit für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen
                    uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
                    Auslieferung der Inhalte verwenden. Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare
                    Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch
                    die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser Webseite,
                    ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer
                    gespeichert werden und unter anderem technische Informationen zum Browser und zum Betriebssystem, zu
                    verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
                    enthalten als auch mit solchen Informationen aus anderen Quellen verbunden werden.</p>
                <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den
                    Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
                    Einwilligung. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen
                    (d.h. Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet.
                    In diesem Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in
                    dieser Datenschutzerklärung hinweisen.</p>
                <ul class="m-elements">
                    <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                        Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                    </li>
                    <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                        Onlinediensten).</li>
                    <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                        Nutzerfreundlichkeit, Vertragliche Leistungen und Service.</li>
                    <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                    </li>
                </ul>
                <p><strong>Eingesetzte Dienste und Diensteanbieter:</strong></p>
                <ul class="m-elements">
                    <li><strong>Google Fonts:</strong> Wir binden die Schriftarten ("Google Fonts") des Anbieters Google
                        ein, wobei die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im Browser
                        der Nutzer verwendet werden. Die Einbindung erfolgt auf Grundlage unserer berechtigten
                        Interessen an einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriftarten,
                        deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher lizenzrechtlicher
                        Restriktionen für deren Einbindung. Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
                        Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
                        View, CA 94043, USA; Website: <a href="https://fonts.google.com/"
                            target="_blank">https://fonts.google.com/</a>; Datenschutzerklärung: <a
                            href="https://policies.google.com/privacy"
                            target="_blank">https://policies.google.com/privacy</a>; Privacy Shield (Gewährleistung
                        Datenschutzniveau bei Verarbeitung von Daten in den USA): <a
                            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active"
                            target="_blank">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>.
                    </li>
                    <li><strong>ReCaptcha:</strong> Wir binden die Funktion "ReCaptcha" zur Erkennung von Bots, z.B. bei
                        Eingaben in Onlineformularen, ein. Die Verhaltensangaben der Nutzer (z.B. Mausbewegungen oder
                        Abfragen) werden ausgewertet, um Menschen von Bots unterscheiden zu können. Dienstanbieter:
                        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google
                        LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website: <a
                            href="https://www.google.com/recaptcha/"
                            target="_blank">https://www.google.com/recaptcha/</a>; Datenschutzerklärung: <a
                            href="https://policies.google.com/privacy"
                            target="_blank">https://policies.google.com/privacy</a>; Privacy Shield (Gewährleistung
                        Datenschutzniveau bei Verarbeitung von Daten in den USA): <a
                            href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active"
                            target="_blank">https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active</a>;
                        Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin: <a
                            href="https://tools.google.com/dlpage/gaoptout?hl=de"
                            target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>, Einstellungen für die
                        Darstellung von Werbeeinblendungen: <a href="https://adssettings.google.com/authenticated"
                            target="_blank">https://adssettings.google.com/authenticated</a>.</li>
                </ul>
                <h2 id="m12">Löschung von Daten</h2>
                <p>Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren
                    zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
                    (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht
                    erforderlich sind).</p>
                <p>Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
                    erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
                    gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
                    steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung,
                    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
                    oder juristischen Person erforderlich ist.</p>
                <p>Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen der einzelnen
                    Datenschutzhinweise dieser Datenschutzerklärung erfolgen.</p>
                <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
                <p>Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir
                    passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten
                    Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine
                    Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung
                    erforderlich wird.</p>
                <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und
                    Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können
                    und bitten die Angaben vor Kontaktaufnahme zu prüfen.</p>
                <h2 id="m10">Rechte der betroffenen Personen</h2>
                <p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15
                    bis 18 und 21 DSGVO ergeben:</p>
                <ul>
                    <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                            Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen
                            Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen;
                            dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie
                            betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie
                            das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
                            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                            Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong></li>
                    <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen
                        jederzeit zu widerrufen.</li>
                    <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
                        betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere
                        Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.</li>
                    <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das
                        Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie
                        betreffenden unrichtigen Daten zu verlangen.</li>
                    <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe
                        der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich
                        gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der
                        Verarbeitung der Daten zu verlangen.</li>
                    <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die
                        Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
                        gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen
                        Verantwortlichen zu fordern.</li>
                    <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner nach Maßgabe der gesetzlichen
                        Vorgaben das Recht, bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres
                        gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes
                        Beschwerde einzulegen, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
                        personenbezogenen Daten gegen die DSGVO verstößt.</li>
                </ul>
                <h2 id="m42">Begriffsdefinitionen</h2>
                <p>In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten
                    Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO
                    definiert. Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen
                    dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch sortiert.</p>
                <ul class="glossary">
                    <li><strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle Informationen, die
                        sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden "betroffene
                        Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder
                        indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
                        zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen
                        Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen,
                        psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person
                        sind. </li>
                    <li><strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder juristische
                        Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die
                        Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet. </li>
                    <li><strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter
                        Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                        personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten,
                        sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen. </li>
                </ul>
                <p class="seal"><a href="https://datenschutz-generator.de/?l=de"
                        title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                        target="_blank">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a>
                </p>
            </div>

        </div>
    </div>

</main>
<!--Main Layout-->