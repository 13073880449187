import { Component, OnInit } from '@angular/core';
import { PopupService } from 'src/app/services/popup.service';
import { myCookieService } from 'src/app/services/cookies.service';

@Component({
  selector: 'app-cookieaccept',
  templateUrl: './cookieaccept.component.html',
  styleUrls: ['./cookieaccept.component.scss']
})
export class CookieacceptComponent implements OnInit {

  constructor(private popUpService : PopupService, private cookies:myCookieService) { }

  ngOnInit(): void {
  }

  acceptCookies(){
    this.popUpService.closeCookieBox();
    this.cookies.consent(true)
  }
  
  denyCookies(){
    this.popUpService.closeCookieBox();
    this.cookies.consent(false)
  }

}
