import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class myCookieService {
  private isConsented: boolean = false;

  constructor(private cookieService : CookieService) { 
    if(this.getCookie('acceptCookies') == 'true'){
      this.isConsented = true;  
    } 
   }

  public getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  public deleteCookie(name) {
    this.setCookie(name, '');
    this.cookieService.delete("jwt");
  }

  public setCookie(name: string, value: string) {
    console.log("Cookie set")
    if (this.isConsented) {

      /*
      let Domain:string = `;Domain=${'modseven.net'}`;
      let d:Date = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      let expires:string = `expires=${d.toUTCString()}`;
      let cpath:string = '/hallo' ? `; path=${'/asd'}` : '/affe';
      
      document.cookie = `${name}=${value}${Domain}`;
*/

      //document.cookie = name + '=' + value
       this.cookieService.set(name,value,null,null,'modseven.net');
       
       
    }
  }


  public consent(isConsent: boolean) {
    console.log(isConsent)
    if (!isConsent) {
      return this.isConsented;
    } else if (isConsent) {
      this.isConsented = true;
      this.setCookie('acceptCookies', 'true');
      
    }
  }

}

