<div id="targetLeistungen" style="height: 5rem;"> </div>
<h2>Unsere Leistungen</h2>

    <!-- TODO: Texte aus Datenbank holen -->
    <div  class="myRow">
        <app-leistcard cardimage="assets/images/software.png" cardtitle="Software" [cardtext]="['Webentwicklung in NodeJS und Angular', 'Entwicklung von Backend APIs', 'Windows und Linux Software','Android Applikationen']"></app-leistcard>
    </div>
    <div class="myRow">
        <app-leistcard cardimage="assets/images/automation.png" cardtitle="Automation" [cardtext]="['Heimautomation', 'SPS nach IEC 61131', 'Pumpentechnik','Aktorik und Sensorik','uvm...']"></app-leistcard>
    </div>
    <div class="myRow">
        <app-leistcard cardimage="assets/images/prototyping.png" cardtitle="Prototyping" [cardtext]="['CAD Konstruktion', 'FDM 3D Druck', 'Hochauflösender SLA Druck','Anpassung an 3D Druck']"></app-leistcard>
    </div>
    <div class="myRow">
        <app-leistcard cardimage="assets/images/schooling.png" cardtitle="Schulungen" [cardtext]="['Programmiersprachen', 'Webapplikationen / Webdesign', 'Embedded Programmierung','Deep Learning','uvm...']"></app-leistcard>
    </div>
