<div class="row colText">
    <div class="cardimage"><img src="{{cardimage}}"></div>
    <div class="myCol">
        <div class="row"><h3>{{cardtitle}}</h3></div>
        <div class="row col-text">
            
            <li *ngFor="let item of cardtext">{{item}}</li>


        </div>    
        </div>
    
  </div> 