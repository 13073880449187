import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Contactmessage } from 'src/app/models/contactmessage';
import { RestclientService } from 'src/app/services/restclient.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';





@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.scss']
})
export class ContactformComponent implements OnInit {

  @Output() sendMessageToServer: EventEmitter<Contactmessage> = new EventEmitter();
  
  
  
  
  
  reactiveForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    subject: new FormControl(''),
    message: new FormControl(''),
    checkDSB: new FormControl('')


  })
 

  constructor(private restclientserivce: RestclientService) { }
  bgColor: string;
  

  ngOnInit(): void {
  }

  onSubmit() {
    var msg = {
      name: this.reactiveForm.get('name').value,
      email: this.reactiveForm.get('email').value,
      phone: this.reactiveForm.get('phone').value,
      subject: this.reactiveForm.get('subject').value,
      message: this.reactiveForm.get('message').value
    }

    console.log(this.reactiveForm.get("checkDSB").value)

    if(this.reactiveForm.get("checkDSB").value != true){
      
      this.bgColor = "background-color: rgba(128, 0, 0,0.25); border: solid thin black;"
      alert("Bitte akzeptieren Sie die Datenschutzbestimmungen");
      
      //this.bgColor = "rgb(255, 255, 255)"
     
      return;
    }

    this.restclientserivce.sendContactMessageToServer(msg).subscribe(response => {
      if (response.status == "OK") {
        this.bgColor = "background-color: rgb(255, 255, 255); border: none;"
        alert("Vielen Dank für Ihre Nachricht");
         this.reactiveForm.reset();
      
      }
      if (response.status == "ERROR") {
        this.bgColor = "background-color: rgb(255, 255, 255); border: none;"
        alert("Bitte füllen Sie alle erforderlichen Felder aus");
      }
    });

  }


}
