<div class="myContainer">
    <div class="row">
        <div class="col-3 ">ID:</div>
        <div class="col-9">{{id}}</div>
    </div>
    <div class="row">
        <div class="col-3 ">Datum</div>
        <div class="col-9">{{datetime}}</div>
    </div>
    <div class="row">
        <div class="col-3">Name</div>
        <div class="col-9">{{name}}</div>
    </div>
    <div class="row">
        <div class="col-3">E-Mail</div>
        <div class="col-9">{{email}}</div>
    </div>
    <div class="row">
        <div class="col-3">Telefon:</div>
        <div class="col-9">{{phone}}</div>
    </div>
    <div class="row">
        <div class="col-3">Betreff</div>
        <div class="col-9">{{subject}}</div>
    </div>
    <div class="row">
        <div class="col-3">Nachricht</div>
        <div class="col-9">{{message}}</div>
    </div>
    <div class="row">
        <div class="col-3"></div>
        <div class="col-9">
            <button (click)="deleteMessage()"
            class="btn btn-primary float-right btn-lg"
            style="background-color: rgb(42, 37, 46); border-color: rgba(255, 255, 255, 0.5);">Löschen</button>

        </div>
    </div>
</div>