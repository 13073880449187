import { Component, OnInit } from '@angular/core';
import { PopupService } from './services/popup.service';
import { myCookieService } from './services/cookies.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'modseven-frontend';
  constructor(private popUpService: PopupService, private cookies: myCookieService) {

  }

  ngOnInit(): void {

    this.popUpService.showAlert({message: "", type: "error",duration:0})

    if (this.cookies.getCookie('acceptCookies') == 'true') {
      this.popUpService.closeCookieBox();
    } else {
      this.popUpService.showCookieBox();
    }
  }


}
