import { Component, OnInit } from '@angular/core';
import { RestclientService } from 'src/app/services/restclient.service';
import { GotMessages } from 'src/app/models/gotmessages';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  messages: GotMessages;
  constructor( private restService: RestclientService) {
    this.restService.getMessages().subscribe(data => {this.messages = data;})
  }


  ngOnInit() {

  }

}
