import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
    '(document:scroll)': 'onScroll($event)'
  }

})
export class DropdownComponent implements OnInit {
  contentVisible : string;
  constructor(private _eref: ElementRef) { }

  ngOnInit(): void {
  }

  openDropDown(){
    this.contentVisible = "visible";
  }
  closeDropDown(){
    this.contentVisible = "collapse";
  }
  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) // or some similar check
    this.closeDropDown();
   }
   onScroll(event){
    this.closeDropDown();
   }
}
