import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RestclientService } from 'src/app/services/restclient.service';
import { Router } from '@angular/router';
import { myCookieService } from 'src/app/services/cookies.service';
import { PopupService } from 'src/app/services/popup.service';
import { UserDetails } from 'src/app/models/UserDetails';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})


export class RegisterComponent implements OnInit {

  @Output() userRegister: EventEmitter<UserDetails> = new EventEmitter();
  @Output() alertTitle: string;
  @Output() alertInformation: string;

  reactiveForm = new FormGroup({
    username: new FormControl(''),
    realname: new FormControl(''),
    address: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    password: new FormControl('')
  })

  constructor(private restclientserivce: RestclientService, private router: Router, private cookies: myCookieService, private popUpService: PopupService) { }

  ngOnInit(): void {

  }
  onSubmit() {
    var userDetails = {
      username: this.reactiveForm.get('username').value,
      password: this.reactiveForm.get('password').value,
      realname: this.reactiveForm.get('realname').value,
      address: this.reactiveForm.get('address').value,
      email: this.reactiveForm.get('email').value,
      phone: this.reactiveForm.get('phone').value,
    }


    return this.restclientserivce.userRegister(userDetails).subscribe(response =>{
      if (response.status == 'OK') {
        this.router.navigate(['/']);
        this.popUpService.showAlert({ message: "Registrierung war erfolgreich - Account wird überprüft", type: "success", duration: 5000 })
      }

      if (response.status == 'ERROR') {
        if (response.message == 'Username already taken')
          this.popUpService.showAlert({ message: "Username schon vorhanden!", type: "error", duration: 5000 })
        if (response.message == 'E-Mail already registered')
          this.popUpService.showAlert({ message: "E-Mail schon vorhanden!", type: "error", duration: 5000 })
        if (response.message == 'Fill out necessary fields')
          this.popUpService.showAlert({ message: "Bitte alles benötigte ausfüllen!", type: "error", duration: 5000 })
      }

    })

  }

}
