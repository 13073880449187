<div id="targetStart" style="height: 5rem;"> </div>
<h2>Interdisziplinäre Dienstleistungen</h2>


<div class="d-flex row">
    <!-- TODO: Texte aus Datenbank holen -->
    <div class="col-text m-5">
        Unser Know-How erstreckt sich über einen weiten Bereich der Mechatronik zum einem und der Softwareentwicklung zum anderen.
        Durch die Kombination verschiedenster Disziplinen erreichen wir besondere Synergien und erschaffen somit stets perfekt
        und an Sie individuell angepasste Schnittstellen für Ihre Systeme und liefern die nötige Dokumentation.
    </div>

    <div class="col-text m-5">
        Im mechatronischen Bereich bieten wir Lösungen für industruielle Automation sowie auch sehr individuelle Lösungen für Ihr Unternehmen
        oder Sie als Privatanwender an. Auf Seite der Softwareentwicklung ersteckt sich unser Wissen von Windows- über Linuxprogrammierung bis hin
        zur Fullstack Webentwicklung sowie Entwicklung von Applikationen für Android Smartphoens.
    </div>
</div>

